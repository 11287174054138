import React from "react"
import { useCourseStore } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterBool from "../../../../../components/course-footer-bool"
import Poem from "../../../../../components/poem"
import { getCourseNavigation } from "../../../../../store/courses"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterBool
          title="Ist das ein Gedicht?"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen/feiertage/loesung"
          onSubmit={(submission) => {
            setAnswer({
              courseId: "versteckte-ordnung",
              chapterId: "01-gedicht-erkennen",
              taskId: "feiertage",
              answer: submission,
            })
          }}
        />
      }
      navigation={navigation}
    >
      <Seo title="Wie wenn am Feiertage" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Wie wenn am Feiertage
          </Heading>
          <Poem>
            <span>Wie wenn am Feiertage, das Feld zu sehn</span>
            <span>Ein Landmann geht, des Morgens, wenn</span>
            <span>Aus heißer Nacht die kühlenden Blize fielen</span>
            <span>Die ganze Zeit und fern noch tönet der Donner,</span>
            <span>In sein Gestade wieder tritt der Strom,</span>
            <span>Und frisch der Boden grünt</span>
            <span>Und von des Himmels erfreuendem Reegen</span>
            <span>Der Weinstok trauft und glänzend</span>
            <span>In stiller Sonne stehn die Bäume des Haines</span>
          </Poem>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
